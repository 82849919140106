import decoder from "jwt-decode";
const checkAuth = () => {
  // return true; //Remove after dev work
  try {
    const queryParams = new URLSearchParams(
      window.location.href.substring(window.location.href.indexOf("?"))
    );
    if (queryParams.has("jwt")) {
      localStorage.setItem("jwt", queryParams.get("jwt"));
      window.location.replace(
        window.location.href.substring(0, window.location.href.indexOf("?"))
      );
    }
    if (localStorage.getItem("jwt")) {
      const claims = decoder(localStorage.getItem("jwt"));
      if (Number(claims.exp) > Date.now() / 1000) {
        return true;
      }
    }
    console.log("Window Location Hostname ", window.location.hostname);
    const isProd =
      window.location.hostname.includes("d19tyzi0ybaipj") ||
      window.location.hostname.includes("cpap-int.uopx.io")
        ? true
        : false;
    console.log("Is Environment prod? ", isProd);

    // If the domain is not PROD, route to non-prod
    const loginUrl = isProd
      ? "https://srsqg2whm3.execute-api.us-east-1.amazonaws.com/prod/auth-init?site=cp"
      : "https://dmqxdf3cz6.execute-api.us-east-1.amazonaws.com/st/auth-init?site=cp";

    window.location.replace(loginUrl);
    return false;
  } catch (err) {
    console.log(`Function checkAuth failed with error:`);
    console.error(err);
  }
};
export default checkAuth;
