<template>
  <div class="flex-container">
    <div class="left">
      <div class="login-panel">
        <PhoenixLogo height="90px" width="90px" />
        <h1 class="message">Communication Platform Admin Portal</h1>
        <a :href="loginUrl" class="button">Sign In with Microsoft</a>
      </div>
    </div>
    <div class="right"></div>
  </div>
</template>

<script>
import PhoenixLogo from "./PhoenixLogo.vue";
export default {
  name: "LoginPage",
  components: {
    PhoenixLogo,
  },
  props: {
    msg: String,
  },
  data() {
    return {
      //If the domain is not PROD, route to non-prod
      loginUrl:
        window.location.hostname.includes("cpap-int.uopx.io") ||
        window.location.hostname.includes("d19tyzi0ybaipj")
          ? "https://srsqg2whm3.execute-api.us-east-1.amazonaws.com/prod/auth-init?site=cp"
          : "https://dmqxdf3cz6.execute-api.us-east-1.amazonaws.com/st/auth-init?site=cp",
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.flex-container {
  display: flex;
  flex-direction: row;
  /* border: yellow solid 5px; */
  height: 100vh;
  background: linear-gradient(to bottom, #5e7079, #3a4f59);
}

.right {
  /* border: blue solid 2px; */
  width: 50vw;
  background-image: url(../assets/login.jpg);
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  object-fit: contain;
}
.left {
  display: flex;
  width: 50vw;
  align-items: center;
  justify-content: center;
}

.login-panel {
  background: #f1f1f1;
  width: 55%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-left: 2vw;
  padding-right: 2vw;
  padding-bottom: 60px;
  padding-top: 40px;
  border-radius: 15px;
  border-radius: 5px;
  box-shadow: 0 3px 10px #d2d7d9;
}
.message {
  width: 100%;
}

h1 {
  margin-top: 30px;
  margin-bottom: 30px;
  color: #464646;
}

.button {
  padding: 0.8rem;
  text-align: center;
  width: 15vw;
  color: #f2f3f3;
  background: #db3825;
  font-size: 1rem;
  font-weight: bold;
  text-decoration: none;
  box-shadow: 0 3px 10px #949494;
}

.button:hover {
  background: #b72717;
  color: #000000;
  transition: 0.3s;
}

.button:active {
  background-color: #567248;
  box-shadow: 0 0.5px #97a3a9;
  transition: none;
  color: #d2d7d9;
}
</style>
