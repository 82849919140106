<template>
    <svg class="phx-svg"
    :style="{height, width}"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 83.11 96.85"
    >
      <defs />
      <title>Bird®</title>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path
            class="cls-1"
            d="M71.74,54.87a9.82,9.82,0,0,0,9.68-8.11c0-.15,0-.31.07-.46.06-.42,0-.32-.14-.25l-.44.18A20.72,20.72,0,0,1,74,47.39H50a3.36,3.36,0,0,1-2-.43c-.09-.07-.14-.08-.08.08.25.72,2.23,6.59,2.23,6.59a2.23,2.23,0,0,0,2.4,1.24Z"
          />
          <path
            class="cls-1"
            d="M50.93,56.13c.08.27,1.23,3.48,1.23,3.48A6.44,6.44,0,0,0,58.25,64H68.57a9.82,9.82,0,0,0,9.76-8.68c0-.28,0-.18-.23-.09a20.93,20.93,0,0,1-7.24,1.29s-17,0-17.76,0a4,4,0,0,1-2-.37c-.08-.05-.28-.21-.19,0"
          />
          <path
            class="cls-1"
            d="M4.62,50a7.78,7.78,0,0,1-2.88-6.28,8.8,8.8,0,0,1,1.18-4.4L22.58,54.79a6.64,6.64,0,0,0,8.82-.22c.11-.1.11-.06.09.06s-.53,3.27-.85,4.61c-.93,4-6.52,6.33-10.23,3.47Z"
          />
          <path
            class="cls-1"
            d="M36.18,29c-1.34,1.37-2.37,3.87-3.08,5.77-.53,1.39-1.23,1.93-2.21,2.07a3.52,3.52,0,0,1-3.05-.88c-1-.79-10.59-8.46-11.25-9-.18.2-5.62,4.95-5.62,4.95L26.3,44c1.13.81,2.32,1.64,3.83,1.44,3.54-.56,4-3.85,4.58-6.4.18-.71.36-1.38.56-2C37.58,29.52,41.39,26,46,23.83c.27-.13.11,0,.06.09-1.71,1.84-2.59,4.84-2.28,8.73.27,3.46,2.56,10.49,2.93,11.4a2.36,2.36,0,0,0,2.41,1.73H73.33a9.67,9.67,0,0,0,9.77-8.5c0-.22.06-.2-.22-.08a21.14,21.14,0,0,1-7.25,1.1s-20.85,0-25,0c-3.42,0-4.1-4.46-4.16-5.51-.51-6.38,2.69-9.2,2.69-9.2A4.56,4.56,0,0,1,53,21.9s.16,0,0-.07c-.46-.34-2.13-1.52-2.92-2.09a2.35,2.35,0,0,0-2.47-.36l-4.73,2a1.51,1.51,0,0,1-1.21-.29,1.06,1.06,0,0,0-1,0s-4.89,2.51-6.6,3.26a3.17,3.17,0,0,1-3.74-1c-.16-.15-.13-.25-.23,0A4.56,4.56,0,0,0,29.8,25a4.29,4.29,0,0,0,1.7,3.72c1.2.79,2.83.71,4.63.11.24-.08.16,0,0,.11"
          />
          <path
            class="cls-1"
            d="M22.18,10.46A10.11,10.11,0,0,0,24.79.27c-.11-.4-.12-.3-.23-.08a21.85,21.85,0,0,1-4.21,4.65L2.11,21.27A6.57,6.57,0,0,0,0,26.52v4.75c0,.34.12.24.17,0,.28-1.23,1.31-2.44,3-4L22.18,10.46"
          />
          <path
            class="cls-1"
            d="M54.57,77c-1.49-4.49-5.79-7.1-8.13-8.76-1-.69-5-3.38-6.66-6-3.4-5.34-1.58-9.3-.09-11.79.38-.67.3-.44.3-.12.06,2.71,1.6,5.77,5.84,8.41,4.69,2.89,10.27,7.47,9.08,18.54-.1.88-.24,0-.34-.26"
          />
          <path
            class="cls-1"
            d="M32.73,78.4a9.2,9.2,0,0,0-1.83,5.89,9.38,9.38,0,0,0,1.59,4.59c.06.11.18.31-.05.16-8.59-5.21-8.51-17.47-1.35-24.65.09-.09.06,0,.05.12-.5,4.47,3.62,8.22,5.8,10.1,1.73,1.5,4.69,3.67,7,5.31a10.57,10.57,0,0,1,4.68,7.47A3.48,3.48,0,0,0,49.12,84C48,80.17,43.05,77.3,41.7,76.21A28,28,0,0,1,35,69.28c-2.09-3.26-1.84-9.51.2-11.65.19-.2.15-.1.16.14-.05,3.24,2.12,5.09,3.52,6.6,1.88,2,8.53,6.59,10.21,8.12,4.05,3.71,6,10.08,2.65,16.73C50.19,92.3,47.62,96,40.22,96.85c-.33,0-.27-.07-.09-.15a5.27,5.27,0,0,0,3.23-5.4c-.11-2.91-1.9-4.54-4.74-6.52-3.54-2.26-5.12-4.53-5.89-6.38"
          />
          <path
            class="cls-1"
            d="M23.8,53.6c3.67,2.52,8.07.06,8.85-4.45.25-1.1.69-3.31.7-3.4s0-.09-.08,0c-2.7,2.15-5,1.73-7.69-.13L9.66,33s-5,4.63-5.52,5.1Z"
          />
          <path
            class="cls-1"
            d="M0,41c0,.39.12.25.2-.06.41-1.83,2.13-3.41,3.72-5,0,0,.14-.17,2.39-2.21S19.86,21.89,19.86,21.89c2.45-2.22,4.93-6.11,3.9-10.64-.07-.31-.1-.16-.19-.05a31.54,31.54,0,0,1-4.28,4.11C14.86,19.18,7.83,25.32,4,28.79a19.34,19.34,0,0,0-2.82,2.84A6.6,6.6,0,0,0,0,35.85Z"
          />
          <path
            class="cls-1"
            d="M58.3,90.4A2.36,2.36,0,1,1,60.62,88,2.33,2.33,0,0,1,58.3,90.4m0-5.15A2.8,2.8,0,1,0,61.15,88a2.79,2.79,0,0,0-2.85-2.79"
          />
          <path
            class="cls-1"
            d="M58.38,87.8h-.66v-1h.66c.43,0,.56.2.56.47s-.14.48-.56.48m.48.23v0a.75.75,0,0,0,.63-.76c0-.63-.54-.79-1.08-.79H57.19v3.13h.53V88.21h.66a.46.46,0,0,1,.42.27,1.38,1.38,0,0,1,.11.57v.53h.53v-.53a2.76,2.76,0,0,0,0-.38.65.65,0,0,0-.54-.64"
          />
        </g>
      </g>
    </svg>
</template>

<script>
export default {
   name: 'PhoenixLogo',
   props: ["width","height"]
}
</script>

<style scoped>
.cls-1 {
  fill: #db3825;
}
</style>
