<!-- eslint-disable max-len -->
<template>
  <nav class="navbar">
    <div class="navbar-title-container">
      <PhoenixLogo height="60px" width="60px" />
      <h1>| Communication Platform Admin Portal</h1>
    </div>
    <div class="nav-links-container">
      <router-link
        class="nav-link"
        v-for="route in links"
        v-bind:key="route.id"
        :to="route.page"
        >{{ route.text }}
      </router-link>
    </div>
  </nav>
</template>

<script>
import PhoenixLogo from "./PhoenixLogo.vue";
export default {
  name: "Navbar",
  components: {
    PhoenixLogo,
  },
  data() {
    return {
      links: [
        {
          id: 0,
          text: "Home",
          page: "/home",
        },
        // {
        //   id: 1,
        //   text: "Onboarding",
        //   page: "/onboarding",
        // },
        {
          id: 1,
          text: "Voicemail",
          page: "/voicemail",
        },
      ],
    };
  },
};
</script>

<style scoped>
nav {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  top: 0;
  width: 100vw;
  padding-top: 10px;
  background-color: #1d333d;
  box-shadow: 5px 10px 18px #69686862;
}
.nav-links-container {
  display: flex;
  width: auto;
}
.nav-link {
  color: #ffffff;
  padding: 2vh 2vw;
  text-decoration: none;
  font-size: 20px;
  font-weight: 600;
  display: block;
  border-bottom: 3px solid #1d333d;
}
.nav-link:hover,
.router-link-active {
  cursor: pointer;
  border-bottom: 3px solid white;
}
.phx-svg {
  margin-left: 15px;
}
.navbar-title-container {
  width: 40vw;
  display: flex;
  align-items: center;
}
h1 {
  margin-left: 5px;
  color: white;
}
/* 
.logo {
  display: flex;
  align-items:center;
  justify-content:center;
  color: #f2f2f2;
  width:6vw;
  background: url(../assets/green50x50.png) center no-repeat;
  z-index: 5;
}
nav a:hover {
  background-color: #d2d7d9;
  color: #567248;
  transition: .6s;
  text-decoration: none;
  border-radius: .6rem;
}

nav a:active {
  background-color: #567248;
  box-shadow: 0 .5px #97a3a9;
  transition: none;
  color: #d2d7d9;
} */
</style>
