<template>
  <!-- <Navbar/> -->
  <div class="container">
    <div class="landingPage">
      <div class="background-image"></div>
      <h1>Welcome to the Communication Platform Agent Portal</h1>
    </div>
  </div>
</template>

<script>
// import Navbar from './Navbar.vue';

export default {
  name: "LandingPage",
  // props: {
  //   msg: String,
  // },
  // components: {
  //   Navbar,
  // },
};
</script>

<style scoped>
h1 {
  font-size: 10vh;
  color: #e9e3ca;
  position: absolute;
  left: 50vw;
  top: 16vh;
  transform: translateX(-50%);
  white-space: inherit;
  -webkit-text-stroke: 2px #000304; /*font width and color */
}
.background-image {
  height: 100vh;
  background: url(../assets/landingPage.jpg) center no-repeat;
  filter: blur(6px);
  -webkit-filter: blur(6px);
  background-size: cover;
}
</style>
