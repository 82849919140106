<template>
  <!-- <Navbar/> -->
  <div class="voicemail-support-page">
    <div class="voicemail-support-options" >
      <h1>Voicemail Support Options</h1>
      <ul class="options">
        <li>
          <router-link :to="{ name: 'SearchAgentVM' }">Search</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'AddAgentVM' }">Add Agent</router-link>
        </li>
      </ul>
    </div>
    <RouterView></RouterView>
  </div>
</template>

<script>
// import Navbar from './Navbar.vue';
export default {
  name: "Voicemail",
};
</script>

<style scoped>
h1 {
  margin-bottom: 40px;
  margin-top: 40px;
}

.voicemail-support-page {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.options {
  margin-top: 40px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.options a {
  text-decoration: none;
  padding: 15px;
  background-color: crimson;
  color: white;
  font-weight: 600;
}

.options a:active {
  background-color: #97CACF;
}

.options li.active {
  background-color: green;
}

.options a:hover {
  background-color: #97CACF;
}

.router-link-active {
  cursor: pointer;
  border-bottom: 3px solid rgb(24, 12, 156);
}
</style>
