import { createRouter, createWebHistory } from "vue-router";
import LoginPage from "../components/LoginPage.vue";
import LandingPage from "../components/LandingPage.vue";
import Voicemail from "../components/VoicemailPage.vue";
import SearchVMPage from "../components/SearchAgentvmPage.vue";
import checkAuth from "../auth/checkAuth";

export default createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      name: "Login",
      component: LoginPage,
    },
    {
      path: "/home",
      name: "Landing",
      component: LandingPage,
      beforeEnter: (to, from) => {
        return checkAuth();
      },
    },
    // {
    //   path: "/onboarding",
    //   name: "Onboarding",
    //   component: () => import("../components/OnboardingPage.vue"),
    //   beforeEnter: (to, from) => {
    //     return checkAuth();
    //   },
    // },
    {
      path: "/voicemail",
      name: "Voicemail",
      component: Voicemail,
      beforeEnter: (to, from) => {
        return checkAuth();
      },
      children: [
        {
          path: "/searchAgentVM",
          name: "SearchAgentVM",
          component: SearchVMPage,
        },
        {
          path: "/addAgentVM",
          name: "AddAgentVM",
          component: () => import("../components/AddAgentvmPage.vue"),
        },
      ],
    },
  ],
});
